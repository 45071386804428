import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SingleRaisedFloorQuery from "../graphql/SingleRaisedFloorQuery"
import PageHeader from "./PageHeader"
import Breadcrumbs from "../components/Breadcrumbs"
import FloorProductIntroduction from "../components/FloorProductIntroduction"
import SingleHuldaasQuery from "../graphql/SingleHuldaasQuery"
import { useTranslation } from "react-i18next"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import {
  HivsentserDesc,
  HivsentserNiitlegShinj,
} from "../graphql/HivsentserSmallQueries"
import SingleProductSwiper from "../components/SingleProductSwiper"
import {
  BrandText,
  ColorText,
  CountryText,
  NameText,
  TailbarText,
  YndsenShinjText,
} from "../graphql/QueryById"
import PageTitle from "../components/PageTitle"
import SEO from '../components/seo'

const HivsentserSinglePageTemplate = ({ data: { airtable, hemjees } }) => {
  // console.log(hemjees)
  // console.log(airtable.data)
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const mn = currentLanguage === "mn"
  // console.log(HivsentserNiitlegShinj())
  const productType = HivsentserDesc() ? HivsentserDesc()[0]?.name : undefined

  const hemjeePath = hemjees ? hemjees?.nodes[0]?.data : undefined
  const hemjeeNameMn = hemjeePath?.nameMn ?? undefined
  const hemjeeNameEn = hemjeePath?.nameEn ?? undefined
  const hemjeeName = mn ? hemjeeNameMn : hemjeeNameEn

  const hemjeeUnitMn = hemjeePath?.unitMn ?? undefined
  const hemjeeUnitEn = hemjeePath?.unitEn ?? undefined
  const hemjeeUnit = mn ? hemjeeUnitMn : hemjeeUnitEn

  const hemjeeSharpPath = hemjeePath?.Icon ?? undefined
  const hemjeeSharp = hemjeeSharpPath
    ? getImage(hemjeeSharpPath.localFiles[0])
    : undefined
  const hemjeeIcon = hemjeeSharpPath ? (
    <GatsbyImage image={hemjeeSharp} alt={hemjeeName} className="w-full" />
  ) : undefined

  // ##############################################
  // ############## VARIABLES #####################
  const id = airtable?.id ?? undefined
  const table = airtable?.table ?? undefined
  const path = airtable?.data ?? undefined
  const name = path?.Name?.trim() ?? undefined
  const size = path?.size ?? undefined

  const textMn = path?.textMn?.trim() ?? undefined
  const textEn = path?.textEn?.trim() ?? undefined
  const text = mn ? textMn : textEn

  const sharpPath = path?.Image ?? undefined
  // console.log(Array.isArray(sharpPath.localFiles))
  const image = Array.isArray(sharpPath.localFiles)
    ? sharpPath.localFiles.map((item, index) => {
        const sharp = item ? getImage(item) : undefined
        const image = sharp ? (
          <GatsbyImage
            key={index}
            image={sharp}
            alt={`${name}-${index}`}
            className="w-full"
          />
        ) : undefined
        return image
      })
    : [
        <GatsbyImage
          image={sharpPath?.localFiles[0]}
          alt={name}
          className="w-full"
        />,
      ]

  // const sharp = sharpPath ? getImage(sharpPath.localFiles[0]) : undefined
  // const imageBefore = sharp ? (
  //   <GatsbyImage image={sharp} alt={name} className="w-full" />
  // ) : undefined
  // const image = [imageBefore]

  const countryPath = path?.country ?? undefined
  const countrySubPath = countryPath ? countryPath[0].data : undefined
  const countryNameMn = countrySubPath?.mn?.trim() ?? undefined
  const countryNameEn = countrySubPath?.en?.trim() ?? undefined
  const countryName = mn ? countryNameMn : countryNameEn
  const flagPath = countrySubPath?.Flag ?? undefined
  const flagSharp = flagPath ? getImage(flagPath.localFiles[0]) : undefined
  const flag = flagSharp ? (
    <GatsbyImage image={flagSharp} alt={countryName} className="w-full" />
  ) : undefined

  const brandPath = path?.Brand ? path?.Brand[0]?.data : undefined
  const brandNameMn = brandPath?.mn?.trim() ?? undefined
  const brandNameEn = brandPath?.en?.trim() ?? undefined
  const brandName = mn ? brandNameMn : brandNameEn
  const brandLogoPath = brandPath?.Logo ?? undefined
  const brandLogoSharp = brandLogoPath
    ? getImage(brandLogoPath?.localFiles[0])
    : undefined
  const brandLogo = brandLogoSharp ? (
    <GatsbyImage image={brandLogoSharp} alt={brandName} className="w-full" />
  ) : undefined

  const colorPath = path?.Color ?? undefined
  const color = colorPath?.map((item, index) => {
    const path = item?.data ?? undefined
    const hex = path?.hex ?? undefined
    const nameMn = path?.nameMn ?? undefined
    const nameEn = path?.nameEn ?? undefined
    const colorName = mn ? nameMn : nameEn
    // const genColor = path?.genColor ?? undefined
    const baraan = path?.baraan ?? undefined
    return { hex, colorName, baraan }
  })

  const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1)

  const [zoom, setZoom] = useState(false)
  const [zoomImage, setZoomImage] = useState(null)
  const niitleg = HivsentserNiitlegShinj()

  return (
    <Layout>
      <SEO title={name} />
      <PageTitle title={name} />
      {/* <PageHeader title={name} /> */}
      <div className="relative z-10 max-w-screen-lg mx-auto my-10 md:shadow-xl md:p-10 md:bg-white">
        <Breadcrumbs
          productType={productType}
          productName={name}
          nameEn="carpet-tile"
        />

        {/* ####################### MAIN RETURN START ###################### */}
        {/* ################################################################ */}
        <div className="flex flex-col mx-auto md:flex-row">
          <div className="relative py-4 md:py-10 md:w-96">
            {image && (
              <SingleProductSwiper
                images={image}
                setZoomImage={setZoomImage}
                setZoom={setZoom}
              />
            )}
          </div>
          <div className="relative flex-1 mx-auto">
            {/* zoom image */}
            {image && (
              <div
                className={`absolute inset-0 object-contain overflow-hidden z-10 bg-gray-50   -ml-2 shadow-lg -sm:mr-2 xl:-mr-24 select-none ${
                  zoom ? "hidden md:flex" : "hidden"
                } `}
              >
                {zoomImage}
              </div>
            )}

            {/* Үндсэн мэдээлэл хэсэг */}
            <div className="my-2 md:my-10">
              {/* // ################### Imported from Single Product START const */}
              <div className="max-w-xl divide-y ">
                <div className="grid grid-cols-4">
                  <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                    {NameText()}
                  </div>
                  <div className="flex items-center object-contain grid-cols-1 col-span-3 gap-2 py-2 pl-4 font-semibold">
                    {capitalizedName}
                  </div>
                </div>
                <div className="grid grid-cols-4 ">
                  <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                    {YndsenShinjText()}
                  </div>
                  <div className="relative flex flex-wrap items-center object-contain col-span-3 gap-1 py-1 pl-4">
                    {niitleg.map((item, index) => (
                      <div key={index} className="flex group">
                        <div className="flex w-8 h-full m-1 mx-1 transform group-hover:scale-125">
                          {item.icon}
                        </div>
                        {item.name && (
                          <div className="absolute inset-x-0 z-20 justify-center hidden w-full px-4 py-2 leading-tight text-center text-white bg-gray-500 border rounded-lg shadow-lg -top-9 group-hover:flex bg-opacity-95">
                            {item.name}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {color && (
                  <div className="grid grid-cols-4 ">
                    <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                      {ColorText()}
                    </div>
                    <div className="relative flex items-center object-contain h-12 col-span-3 gap-2 py-1 pl-4">
                      {color.map((item, index) => (
                        <div key={index} className="relative inline-flex group">
                          <div
                            className="inline-flex w-10 h-10 border rounded-full group-hover:border-2 group-hover:border-brand-darker"
                            style={{ backgroundColor: item.hex }}
                          ></div>
                          <div
                            className="absolute left-0 z-10 items-center hidden p-20 leading-tight text-gray-800 border rounded-full shadow-lg group-hover:flex group-focus:flex -top-36"
                            style={{ backgroundColor: item.hex }}
                          >
                            {item.colorName && (
                              <div
                                className={`absolute inset-0 z-20 items-center justify-center hidden leading-tight rounded-full group-hover:flex group-focus:flex ${
                                  item.baraan ? "text-white" : "text-gray-800"
                                }`}
                              >
                                {item.colorName}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {countryPath && (
                  <div className="grid grid-cols-4 group">
                    <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                      {CountryText()}
                    </div>
                    <div className="grid items-center object-contain h-12 grid-cols-6 col-span-3 gap-2 py-1 pl-4">
                      <div className="relative inline-flex group">
                        <div className="inline-flex w-full h-8 transform shadow group-hover:scale-125 opacity-70 group-hover:opacity-100 group-hover:hidden">
                          {flag}
                        </div>
                        <div className="hidden py-2 font-bold group-hover:flex">
                          {countryName}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {brandPath && (
                  <div className="grid grid-cols-4 group">
                    <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                      {BrandText()}
                    </div>
                    <div className="relative flex items-center object-contain h-12 col-span-3 gap-1 py-1 pl-4 group">
                      {brandLogo ? (
                        <div className="relative inline-flex object-contain w-10 h-full group-hover:opacity-100 group-hover:hidden">
                          {brandLogo}
                        </div>
                      ) : (
                        <div className="inline-flex">{brandName}</div>
                      )}
                      {brandLogo && (
                        <div className="hidden py-2 font-bold group-hover:flex">
                          {brandName}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {text && (
                  <div className="grid grid-cols-4 ">
                    <div className="flex items-center justify-end px-2 text-right bg-gray-50">
                      {TailbarText()}
                    </div>
                    <div className="grid items-center object-contain col-span-3 gap-2 py-1 pl-4 min-h-12">
                      <div className="relative inline-flex text-sm group">
                        {text}
                      </div>
                    </div>
                  </div>
                )}
                {size && (
                  <div className="grid grid-cols-4 group">
                    <div className="flex items-center justify-end px-2 space-x-2 text-right bg-gray-50 ">
                      <div className="hidden group-hover:flex">
                        {hemjeeName}
                      </div>
                      <span className="w-8 h-8 opacity-70 group-hover:hidden">
                        {hemjeeIcon}
                      </span>
                    </div>
                    <div className="flex items-center object-contain col-span-3 gap-1 py-1 pl-4 min-h-12">
                      <div className="relative inline-flex group">{size}</div>
                      <div>({hemjeeUnit})</div>
                    </div>
                  </div>
                )}

                {/* // ################### Imported from Single Product END */}
              </div>
            </div>
          </div>
          {/* ####################### MAIN RETURN END ###################### */}
          {/* ############################################################## */}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GetSingleHivsentser($id: String!) {
    airtable(id: { eq: $id }) {
      data {
        Name
        size: Hivsentser_Size
        textMn: Mongol_tailbar
        textEn: English_tailbar
        ontsloh: Ontsloh_baraa_bolgoh
        Image {
          localFiles {
            childImageSharp {
              gatsbyImageData(
                width: 400
                layout: CONSTRAINED
                # placeholder: BLURRED
              )
            }
          }
        }
        country: Made_In {
          data {
            mn
            en
            Flag {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    layout: CONSTRAINED
                    placeholder: NONE
                  )
                }
              }
            }
          }
        }
        Brand {
          data {
            mn
            en
            Logo {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 200
                    layout: CONSTRAINED
                    placeholder: NONE
                  )
                }
              }
            }
          }
        }
        Color {
          data {
            hex: Hex_Color
            nameMn: mn
            nameEn: en
            baraan: Baraanduu_ungutei
          }
        }
      }
      id
      table
    }
    hemjees: allAirtable(filter: { table: { eq: "Siding Units" } }) {
      nodes {
        data {
          nameMn: Mongol_name
          nameEn: English_name
          unitMn: Mongol_unit_name
          unitEn: English_unit_name
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
  }
`

export default HivsentserSinglePageTemplate
